import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { AppProps } from "next/app";
import { RecoilRoot, RecoilRootProps } from "recoil";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import ThemeWrapper from "@src/common/theme/ThemeWrapper";
import GlobalThemeWrapper from "@src/common/theme/GlobalThemeWrapper";
import { SessionProvider } from "next-auth/react";
import { DefaultLayout } from "@src/common/components/Layouts";
import { useState } from "react";
import { init } from "@amplitude/analytics-browser";
import { useRouteChangeLogger } from "@src/common/logger/ga/useRouteChangeLogger";
import { isSSRAtomKeys, ssrAllAtoms, SSRAllAtoms } from "@src/common/ssrAtoms";
import Auth from "@src/common/auth/components/Auth";

type InitializeRecoilStateType = Extract<
  RecoilRootProps,
  { override?: true }
>["initializeState"];

interface RecoilAppProps {
  initialRecoilState?: SSRAllAtoms;
}

// NOTE: AccessToken을 다시 땡겨오는 interval. 일단 한시간으로 정합니다.
const REFRESH_TOKEN_INTERVAL = 60 * 60;

export default function App({
  Component,
  pageProps,
}: AppProps<RecoilAppProps>) {
  const { initialRecoilState, ...restPageProps } = pageProps;

  init(`${process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY}`);

  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            suspense: true,
            useErrorBoundary: true,
          },
        },
      }),
  );

  const initializeRecoilState: InitializeRecoilStateType = ({ set }) => {
    initialRecoilState &&
      Object.keys(initialRecoilState).map(key => {
        if (isSSRAtomKeys(key)) {
          const value = initialRecoilState[key];
          const atom = ssrAllAtoms[key];
          set(atom, value);
        }
      });
  };

  // google Analytics :: 라우팅 변화할 떄 조회수 측정
  useRouteChangeLogger();

  return (
    <RecoilRoot initializeState={initializeRecoilState}>
      <ThemeWrapper>
        <GlobalThemeWrapper />
        <SessionProvider
          refetchInterval={REFRESH_TOKEN_INTERVAL}
          refetchOnWindowFocus={true}
        >
          <Auth>
            <QueryClientProvider client={queryClient}>
              <DefaultLayout>
                <Component {...restPageProps} />
              </DefaultLayout>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </Auth>
        </SessionProvider>
      </ThemeWrapper>
    </RecoilRoot>
  );
}
