// NOTE: z-index를 임시로 상수로 관리합니다. 왜 이렇게 정했는지 알 수 없어서, 필요에 따라 논의 후 재조정이 필요할 수 있습니다.
// reference: https://github.com/chakra-ui/chakra-ui/blob/3a87f68474221ed402fe5d169f7067763c8436d5/packages/components/theme/src/foundations/z-index.ts
export const zindex = {
  hide: -1,
  auto: "auto",
  base: 0,
  navBar: 1000,
  dropdown: 1000,
  overlay: 1300,
  modal: 1400,
  popover: 1500,
  toast: 1700,
  tooltip: 1800,
} as const;

export type zindexType = typeof zindex;
