import { css } from "@emotion/react";
import { LIGHT_THEME } from "@src/common/theme/theme";

export const MarkdownGlobalStyles = () => css`
  * {
    box-sizing: border-box;
    color: ${LIGHT_THEME.colors.black};
  }

  em {
    font: inherit;
  }
  figure {
    margin: 0;
  }

  body {
    font: inherit;
    vertical-align: baseline;
    background-color: ${LIGHT_THEME.colors.white};

    a {
      color: ${LIGHT_THEME.colors.green500};
      text-decoration: none;
    }
    input,
    button {
      box-shadow: none;
      background: none;
      border: 0;
      color: inherit;
      &:focus,
      &:active {
        outline: none;
      }
    }
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  blockquote,
  pre {
    padding: 0 1em;
    color: #6a737d;
    border-left: 0.25em solid #dfe2e5;
  }
`;
