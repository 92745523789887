import media from "@src/common/theme/devices";
import { css, Theme } from "@emotion/react";
import { GlobalStyleOptions } from "../types/common.types";

export const DOMINO_FONT_REGULAR = `${process.env.NEXT_PUBLIC_S3_BUCKET_STATIC_URL}/common/fonts/Domino-Regular.woff2`;
export const DOMINO_FONT_MEDIUM = `${process.env.NEXT_PUBLIC_S3_BUCKET_STATIC_URL}/common/fonts/Domino-Medium.woff2`;
export const DOMINO_FONT_BOLD = `${process.env.NEXT_PUBLIC_S3_BUCKET_STATIC_URL}/common/fonts/Domino-Bold.woff2`;
export const DOMINO_FONT_SEMI_BOLD = `${process.env.NEXT_PUBLIC_S3_BUCKET_STATIC_URL}/common/fonts/Domino-Semibold.woff2`;
const GMARKET_FONT_LIGHT = `${process.env.NEXT_PUBLIC_S3_BUCKET_STATIC_URL}/common/fonts/GmarketTTFLight.woff2`;
const GMARKET_FONT_MEDIUM = `${process.env.NEXT_PUBLIC_S3_BUCKET_STATIC_URL}/common/fonts/GmarketTTFMedium.woff2`;
const GMARKET_FONT_BOLD = `${process.env.NEXT_PUBLIC_S3_BUCKET_STATIC_URL}/common/fonts/GmarketTTFBold.woff2`;

export const GlobalStyles = (theme: Theme, options?: GlobalStyleOptions) => css`
  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @font-face {
      font-family: "Domino Sans";
      src: local("DominoSans"), url("${DOMINO_FONT_REGULAR}") format("woff2");
      font-weight: 400;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Domino Sans";
      src: local("DominoSans"), url("${DOMINO_FONT_MEDIUM}") format("woff2");
      font-weight: 500;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Domino Sans";
      src: local("DominoSans"), url("${DOMINO_FONT_SEMI_BOLD}") format("woff2");
      font-weight: 600;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Domino Sans";
      src: local("DominoSans"), url("${DOMINO_FONT_BOLD}") format("woff2");
      font-weight: 700;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Gmarket Sans";
      src: local("GmarketSans"), url("${GMARKET_FONT_LIGHT}") format("woff2");
      font-weight: 300;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Gmarket Sans";
      src: local("GmarketSans"), url("${GMARKET_FONT_MEDIUM}") format("woff2");
      font-weight: 400;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Gmarket Sans";
      src: local("GmarketSans"), url("${GMARKET_FONT_BOLD}") format("woff2");
      font-weight: 700;
      font-style: normal;
      font-display: fallback;
    }
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;

    ul,
    li {
      list-style: none;
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    input,
    button {
      box-shadow: none;
      background: none;
      border: 0;
      color: inherit;
      font: inherit;

      &:focus,
      &:active {
        outline: none;
      }
    }

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  em {
    font: inherit;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: "Domino Sans";
    background-color: ${options?.isHome
      ? "#f2f0f2"
      : theme.colors["bg/Primary"]};
  }

  html,
  body {
    -ms-overflow-style: none;
    min-width: ${media.size.mobile}px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  video {
    mask-image: -webkit-radial-gradient(white, black);
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }

  @media ${media.query.mobile} {
    html {
      font-size: 52.5%;
    }
  }
`;
