export const THEME = {
  DARK_SHADOW: "dark",
  LIGHT_SHADOW: "light",
} as const;

export const DARK_SHADOW = {
  shadows: {
    "Base/shadow": "0px 2px 10px rgba(0, 0, 0, 0.25)",
    "Canvas/shadow": {
      shadowColor: "rgba(0, 0, 0, 0.25)",
      shadowOffsetX: 0,
      shadowOffsetY: 2,
      shadowBlur: 35,
    },
  },
};

export const LIGHT_SHADOW = {
  shadows: {
    "Base/shadow": "0px 2px 10px rgba(0, 0, 0, 0.08)",
    "Canvas/shadow": {
      shadowColor: "rgba(0, 0, 0, 0.04)",
      shadowOffsetX: 0,
      shadowOffsetY: 2,
      shadowBlur: 35,
    },
  },
};

const SHADOW = {
  light: LIGHT_SHADOW,
  dark: DARK_SHADOW,
};

export default SHADOW;
