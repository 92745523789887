import {
  CHART_INDICATORS,
  CHART_INDICATOR_KEY,
  INDICATOR_KEY,
  Indicators,
} from "@dominoapp/core-utils";

export const getKeyByIndicator = (indicatorType: Indicators) =>
  Object.keys(CHART_INDICATORS).includes(indicatorType)
    ? CHART_INDICATOR_KEY
    : INDICATOR_KEY;
