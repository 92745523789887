export const getSentryEnvironment = () => {
  if (!process.env.NEXT_PUBLIC_API) {
    console.log("[next.config.js] : 환경변수에 NEXT_PUBLIC_API를 추가해주세요");
    return "unknown";
  }

  if (process.env.NEXT_PUBLIC_API.includes("dev.domino.is")) {
    return "development";
  }
  if (process.env.NEXT_PUBLIC_API.includes("staging.domino.is")) {
    return "staging";
  }
  if (process.env.NEXT_PUBLIC_API.includes("domino.is")) {
    return "production";
  }
  return "unknown";
};
