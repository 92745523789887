import styled from "@emotion/styled";
import { PATH_CATEGORY } from "@src/common/constants/routes";

export const Container = styled.div<{
  pathCategory: keyof typeof PATH_CATEGORY;
}>`
  width: 100%;
  height: 100%;
  background-color: ${({ theme, pathCategory }) => {
    switch (pathCategory) {
      case PATH_CATEGORY.TERMS:
        return theme.colors.white;
      case PATH_CATEGORY.NEED_AUTHORIZE:
        return theme.colors["bg/Deep"];
      case PATH_CATEGORY.AUTH:
      case PATH_CATEGORY.LANDING:
      default:
        return theme.colors["bg/Primary"];
    }
  }};
  color: ${({ theme }) => theme.colors["content/Primary"]};
`;
