import { ReactNode } from "react";
import Head from "next/head";
import * as S from "./styled";
import { useRouter } from "next/router";
import { getPathCategory, PATHS } from "@src/common/constants/routes";
import { DOMINO_OG_IMAGE } from "@dominoapp/core-utils";

export interface DefaultLayout {
  children: ReactNode;
}

export const DefaultLayout = ({ children }: DefaultLayout) => {
  const router = useRouter();

  /* NOTE: 비로그인 종목 상세 페이지의 경우 별도의 meta tag 정보를 사용합니다 */
  if (router.pathname === PATHS.STOCK_DETAIL) return <>{children}</>;

  const pathCategory = getPathCategory(router.pathname);

  return (
    <>
      <Head>
        <title>도미노 - 모든 투자를 관리하세요</title>
        <meta property="og:type" content="website" />
        <meta
          property="og:site_name"
          content="도미노 - 모든 투자를 관리하세요"
        />
        <meta property="og:image" content={`${DOMINO_OG_IMAGE}`} />
        <meta
          property="og:description"
          content="도미노 - 모든 투자를 관리하세요"
        />
        <meta name="description" content="도미노 - 모든 투자를 관리하세요" />
        <meta
          name="apple-itunes-app"
          content="app-id=1540576519, app-argument=neostock://main"
        />
        <link
          rel="icon"
          href="https://domino-web-assets.s3.ap-northeast-2.amazonaws.com/web/favicon.ico"
        />
      </Head>
      <S.Container pathCategory={pathCategory}>{children}</S.Container>
    </>
  );
};
