import { RecoilState } from "recoil";
import {
  selectedStockInfoFromSSR,
  SELECTED_STOCK_ASSET_CLASS,
} from "@src/domains/Chart/recoil/atoms";

export const AtomKeys = [SELECTED_STOCK_ASSET_CLASS] as const;
type AtomKeyType = (typeof AtomKeys)[number];

export const isSSRAtomKeys = (key: string): key is AtomKeyType => {
  return AtomKeys.includes(key as AtomKeyType);
};

export type SSRAllAtoms = {
  [key in AtomKeyType]: RecoilState<any>;
};

export const ssrAllAtoms: SSRAllAtoms = {
  [SELECTED_STOCK_ASSET_CLASS]: selectedStockInfoFromSSR,
};
