import { ASSETS, CHART_INDICATORS, INDICATORS } from "@dominoapp/core-utils";
import { StockInfoDTO } from "@src/common/types/stockInfo.type";
import {
  DefaultValue,
  atom,
  selector,
  selectorFamily,
  GetRecoilValue,
} from "recoil";
import {
  ChartCommonState,
  ChartLayoutState,
  ChartSplitType,
  ChartState,
  MultiChartControllerState,
  MultiChartState,
} from "../types/chart.types";
import type { TRENDING_TAB_OPTION_TYPE } from "../types/trendingTab.types";
import { match } from "ts-pattern";
import { Indicators } from "@dominoapp/core-utils";
import { getKeyByIndicator } from "../utils/Indicator/getKeyByIndicator";

import { clamp, cloneDeep, sortBy } from "lodash-es";
import {
  BOLLINGER_BAND_INITIAL_INPUTS,
  MACD_INITIAL_INPUTS,
  MOVING_AVERAGE_INITIAL_INPUTS,
  RSI_INITIAL_INPUTS,
  STOCHASTIC_INITIAL_INPUTS,
  VOLUME_PROFILE_INITIAL_INPUTS,
} from "../constants";

/* NOTE:
   멀티차트 상태를 관리합니다. 최대 4개이며, 각 인덱스로 해당 뷰의 차트 정보를 불러올 수 있습니다.
   ex) multiChartState[1]
*/
export const MULTICHART_STATE = "multiChartState";
export const DEFAULT_MULTICHART_STATE = {
  1: null,
  2: null,
  3: null,
  4: null,
};
export const multiChartState = atom<MultiChartState>({
  key: MULTICHART_STATE,
  default: DEFAULT_MULTICHART_STATE,
});

export const selectedMultiChartIds = selector({
  key: "selectedMultiChartChartIds",
  get: ({ get }) => {
    const multiCharts = get(multiChartState);

    return Object.values(multiCharts)
      .filter(Boolean)
      .map((chart: ChartState) => chart.id);
  },
});

export const MULTICHART_STATE_BY_ID = "multiChartById";
export const multiChartStateById = selectorFamily({
  key: MULTICHART_STATE_BY_ID,
  get:
    (id: ChartSplitType) =>
    ({ get }) => {
      const chartStates = get(multiChartState);
      return chartStates[id];
    },
  set:
    (id: ChartSplitType) =>
    ({ get, set }, value) => {
      const chartStates = get(multiChartState);
      const chartState = chartStates[id];

      if (value instanceof DefaultValue) {
        set(multiChartState, prevValue => ({
          ...prevValue,
          [id]: chartState,
        }));
        return;
      }

      set(multiChartState, prevValue => ({
        ...prevValue,
        [id]: value,
      }));
    },
});

export const GET_MULTI_CHART_BY_ID = "getMultiChartById";

/**
 * @parmas id: ChartSplitType
 * @parmas indicator: Indicators
 * @returns IndicatorOption
 * @description
 * 해당 차트의 indicator에 대한 옵션을 반환합니다.
 * 해당 차트가 존재하지 않을 경우 null을 반환합니다.
 * 해당 차트가 존재하지만, 해당 indicator가 존재하지 않을 경우 해당 indicator의 기본 옵션을 반환합니다.
 * @example
 * const movingAverageOption = useRecoilValue<MovingAverageOptionType>(getIndicatorOption(index, INDICATORS.MOVING_AVERAGE));
 */
export const indicatorOption = selectorFamily({
  key: GET_MULTI_CHART_BY_ID,
  get:
    ({ id, indicator }: { id: ChartSplitType; indicator: Indicators }) =>
    ({ get }: { get: GetRecoilValue }) => {
      const chartState = get(multiChartStateById(id));

      if (chartState === null) return null;

      return match(indicator)
        .with(CHART_INDICATORS.MOVING_AVERAGE, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const indicatorOption = chartState[indicatorKey]?.[indicator];

          const { min, max } = MOVING_AVERAGE_INITIAL_INPUTS[0];

          const option = sortBy(indicatorOption.value, "windowSize").map(
            ({ windowSize }: { windowSize: number }, idx) => ({
              windowSize: clamp(windowSize, min, max),
              id: idx,
            }),
          );

          return { ...cloneDeep(indicatorOption), value: option };
        })
        .with(CHART_INDICATORS.BOLLINGER_BAND, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const indicatorOption = chartState[indicatorKey]?.[indicator];

          const { min: windowSizeMin, max: windowSizeMax } =
            BOLLINGER_BAND_INITIAL_INPUTS[0];
          const { min: multiplierMin, max: multiplierMax } =
            BOLLINGER_BAND_INITIAL_INPUTS[1];
          const { windowSize, multiplier } = indicatorOption;

          return {
            ...cloneDeep(indicatorOption),
            windowSize: clamp(windowSize, windowSizeMin, windowSizeMax),
            multiplier: clamp(multiplier, multiplierMin, multiplierMax),
          };
        })
        .with(CHART_INDICATORS.VOLUME_PROFILE, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const indicatorOption = chartState[indicatorKey]?.[indicator];

          const { bins } = indicatorOption;
          const { min, max } = VOLUME_PROFILE_INITIAL_INPUTS[0];

          return {
            ...cloneDeep(indicatorOption),
            bins: clamp(bins, min, max),
          };
        })
        .with(INDICATORS.MACD, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const indicatorOption = chartState[indicatorKey]?.[indicator];

          const { min: slowMin, max: slowMax } = MACD_INITIAL_INPUTS[0];
          const { min: fastMin, max: fastMax } = MACD_INITIAL_INPUTS[1];
          const { min: signalMin, max: signalMax } = MACD_INITIAL_INPUTS[2];
          const { slow, fast, signal } = indicatorOption;

          return {
            ...cloneDeep(indicatorOption),
            slow: clamp(slow, slowMin, slowMax),
            fast: clamp(fast, fastMin, fastMax),
            signal: clamp(signal, signalMin, signalMax),
          };
        })
        .with(INDICATORS.RSI, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const indicatorOption = chartState[indicatorKey]?.[indicator];

          const { min: windowSizeMin, max: windowSizeMax } =
            RSI_INITIAL_INPUTS[0];
          const { windowSize } = indicatorOption;

          return {
            ...cloneDeep(indicatorOption),
            period: clamp(windowSize, windowSizeMin, windowSizeMax),
          };
        })
        .with(INDICATORS.STOCHASTIC, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const indicatorOption = chartState[indicatorKey]?.[indicator];

          const { min: windowSizeMin, max: windowSizeMax } =
            STOCHASTIC_INITIAL_INPUTS[0];
          const { min: kWindowSizeMin, max: kWindowSizeMax } =
            STOCHASTIC_INITIAL_INPUTS[1];
          const { min: dWindowSizeMin, max: dWindowSizeMax } =
            STOCHASTIC_INITIAL_INPUTS[2];
          const { windowSize, kWindowSize, dWindowSize } = indicatorOption;

          return {
            ...cloneDeep(indicatorOption),
            windowSize: clamp(windowSize, windowSizeMin, windowSizeMax),
            kWindowSize: clamp(kWindowSize, kWindowSizeMin, kWindowSizeMax),
            dWindowSize: clamp(dWindowSize, dWindowSizeMin, dWindowSizeMax),
          };
        })
        .run();
    },
  set:
    ({ id, indicator }: { id: ChartSplitType; indicator: Indicators }) =>
    ({ get, set }, value) => {
      const chartState = get(multiChartStateById(id));

      if (chartState === null) return;

      match(indicator)
        .with(CHART_INDICATORS.MOVING_AVERAGE, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const options = chartState[indicatorKey]?.[indicator];

          const { min, max } = MOVING_AVERAGE_INITIAL_INPUTS[0];
          const values = value.map((option: { windowSize: number }) => ({
            windowSize: clamp(option.windowSize, min, max),
          }));

          const newOptions = { ...cloneDeep(options), value: values };

          set(multiChartState, prevValue => ({
            ...prevValue,
            [id]: {
              ...prevValue[id],
              [indicatorKey]: {
                ...prevValue[id]?.[indicatorKey],
                [indicator]: newOptions,
              },
            },
          }));
        })
        .with(CHART_INDICATORS.BOLLINGER_BAND, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const options = chartState[indicatorKey]?.[indicator];

          const { min: windowSizeMin, max: windowSizeMax } =
            BOLLINGER_BAND_INITIAL_INPUTS[0];
          const { min: multiplierMin, max: multiplierMax } =
            BOLLINGER_BAND_INITIAL_INPUTS[1];
          const { windowSize, multiplier } = value;

          const newOptions = {
            ...cloneDeep(options),
            windowSize: clamp(windowSize, windowSizeMin, windowSizeMax),
            multiplier: clamp(multiplier, multiplierMin, multiplierMax),
          };

          set(multiChartState, prevValue => ({
            ...prevValue,
            [id]: {
              ...prevValue[id],
              [indicatorKey]: {
                ...prevValue[id]?.[indicatorKey],
                [indicator]: newOptions,
              },
            },
          }));
        })
        .with(CHART_INDICATORS.VOLUME_PROFILE, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const options = chartState[indicatorKey]?.[indicator];

          const { bins } = value;
          const { min, max } = VOLUME_PROFILE_INITIAL_INPUTS[0];

          const newOptions = {
            ...cloneDeep(options),
            bins: clamp(bins, min, max),
          };

          set(multiChartState, prevValue => ({
            ...prevValue,
            [id]: {
              ...prevValue[id],
              [indicatorKey]: {
                ...prevValue[id]?.[indicatorKey],
                [indicator]: newOptions,
              },
            },
          }));
        })
        .with(INDICATORS.MACD, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const options = chartState[indicatorKey]?.[indicator];

          const { min: slowMin, max: slowMax } = MACD_INITIAL_INPUTS[0];
          const { min: fastMin, max: fastMax } = MACD_INITIAL_INPUTS[1];
          const { min: signalMin, max: signalMax } = MACD_INITIAL_INPUTS[2];
          const { slow, fast, signal } = value;

          const newOptions = {
            ...cloneDeep(options),
            slow: clamp(slow, slowMin, slowMax),
            fast: clamp(fast, fastMin, fastMax),
            signal: clamp(signal, signalMin, signalMax),
          };

          set(multiChartState, prevValue => ({
            ...prevValue,
            [id]: {
              ...prevValue[id],
              [indicatorKey]: {
                ...prevValue[id]?.[indicatorKey],
                [indicator]: newOptions,
              },
            },
          }));
        })
        .with(INDICATORS.RSI, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const options = chartState[indicatorKey]?.[indicator];

          const { min, max } = RSI_INITIAL_INPUTS[0];
          const { windowSize } = value;

          const newOptions = {
            ...cloneDeep(options),
            windowSize: clamp(windowSize, min, max),
          };

          set(multiChartState, prevValue => ({
            ...prevValue,
            [id]: {
              ...prevValue[id],
              [indicatorKey]: {
                ...prevValue[id]?.[indicatorKey],
                [indicator]: newOptions,
              },
            },
          }));
        })
        .with(INDICATORS.STOCHASTIC, () => {
          const indicatorKey = getKeyByIndicator(indicator);
          const options = chartState[indicatorKey]?.[indicator];

          const { min: windowSizeMin, max: windowSizeMax } =
            STOCHASTIC_INITIAL_INPUTS[0];
          const { min: kWindowSizeMin, max: kWindowSizeMax } =
            STOCHASTIC_INITIAL_INPUTS[1];
          const { min: dWindowSizeMin, max: dWindowSizeMax } =
            STOCHASTIC_INITIAL_INPUTS[2];
          const { windowSize, kWindowSize, dWindowSize } = value;

          const newOptions = {
            ...cloneDeep(options),
            windowSize: clamp(windowSize, windowSizeMin, windowSizeMax),
            kWindowSize: clamp(kWindowSize, kWindowSizeMin, kWindowSizeMax),
            dWindowSize: clamp(dWindowSize, dWindowSizeMin, dWindowSizeMax),
          };

          set(multiChartState, prevValue => ({
            ...prevValue,
            [id]: {
              ...prevValue[id],
              [indicatorKey]: {
                ...prevValue[id]?.[indicatorKey],
                [indicator]: newOptions,
              },
            },
          }));
        })
        .run();
    },
});

/* NOTE:
   차트의 현재 layout 상태입니다. 
   rows, columns의 개수와 현재 split 상태("SINGLE" | "VERTICAL" | "SQUAURE")로 구성됩니다.
*/
export const CHART_LAYOUT_STATE = "chartLayoutState";
export const chartLayoutState = atom<ChartLayoutState>({
  key: CHART_LAYOUT_STATE,
  default: { rows: 1, columns: 1, layout: "SINGLE" },
});

/* NOTE:
   현재 Selected된 Chart의 index입니다.
   multiChart[selected_chart]로 현재 차트에 대한 정보를 얻어올 수 있습니다.
*/
export const SELECTED_CHART_STATE = "selectedChartState";
export const selectedChartState = atom<ChartSplitType>({
  key: SELECTED_CHART_STATE,
  default: 1,
});

/* NOTE:
   차트 검색 Modal state입니다.
*/

export const MODAL_STATE = "modalState";
export const modalState = atom<boolean>({
  key: MODAL_STATE,
  default: false,
});

export const TRENDING_TAB_STATE = "trendingTabState";
export const trendingTabState = atom<TRENDING_TAB_OPTION_TYPE>({
  key: TRENDING_TAB_STATE,
  default: ASSETS.STOCK,
});

export const SELECTED_STOCK_ASSET_CLASS = "__ssr_selectedStockAssetClass";
export const selectedStockInfoFromSSR = atom<Pick<
  StockInfoDTO,
  "id" | "type"
> | null>({
  key: SELECTED_STOCK_ASSET_CLASS,
  default: null,
});

export const CHART_PANEL_SIZE = "chartPanelSize";
export const chartPanelSize = atom<{
  height: number;
  width: number;
}>({
  key: CHART_PANEL_SIZE,
  default: {
    width: 320,
    height: 440,
  },
});

export const MULTI_CHART_CONTROLLER_STATE = "multiChartControllerState";
export const multiChartControllerState = atom<MultiChartControllerState>({
  key: MULTI_CHART_CONTROLLER_STATE,
  default: {
    chartIndicator: false,
    indicator: false,
    interval: false,
    type: false,
  },
});

export const MULTI_CHART_COMMON_STATE = "multiChartCommonState";
export const multiChartCommonState = atom<ChartCommonState | null>({
  key: MULTI_CHART_COMMON_STATE,
  default: null,
});

/**
 * @description 멀티차트의 인디케이터 토스트 상태를 관리합니다. 한번 떳던 토스트에 대해선 다시 뜨지 않기 위해 사용합니다.
 */
export const MULTI_CHART_INDICATOR_TOAST_STATE =
  "multiChartIndicatorToastState";
export const multiChartIndicatorToastState = atom<{
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
}>({
  key: MULTI_CHART_INDICATOR_TOAST_STATE,
  default: {
    1: false,
    2: false,
    3: false,
    4: false,
  },
});

export const currentResizingTarget = atom<HTMLElement | null>({
  key: "currentResizingTarget",
  default: null,
});
