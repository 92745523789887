export const THEME = {
  DARK_MODE: "dark",
  LIGHT_MODE: "light",
} as const;

export const DARK_THEME = {
  colors: {
    "Shallow/Shallow below": "#C4C4C4",
    "bg/Accent": "#3FE964",
    "bg/AccentLow": "#3FE9641A",
    "bg/AlwaysDark": "#000000",
    "bg/AlwaysLight": "#FFFFFF",
    "bg/Deep": "#09090A",
    "bg/Dim": "#0000004D",
    "bg/Disabled": "#CCE5FF14",
    "bg/DownKrLow": "#3398FF1A",
    "bg/DownUsLow": "#FF587A1A",
    "bg/Elevated": "#1A1C1F",
    "bg/InversePrimary": "#FFFFFF",
    "bg/InverseSecondary": "#ECEFF2",
    "bg/Negative": "#FF587A",
    "bg/NegativeLow": "#FF57781A",
    "bg/Overlay": "#00000066",
    "bg/Positive": "#3399FF",
    "bg/PositiveLow": "#3399FF1A",
    "bg/Primary": "#131314",
    "bg/SameLow": "#CDE6FF14",
    "bg/Secondary": "#CCE5FF14",
    "bg/Tertiary": "#CCE5FF24",
    "bg/UpKrLow": "#FF587A1A",
    "bg/UpUsLow": "#3FE9651A",
    "bg/Warning": "#FFDD33",
    "bg/WarningLow": "#FFDD331A",
    black: "#000000",
    blue100: "#ADD6FF",
    blue200: "#84C1FF",
    blue300: "#5CADFF",
    blue400: "#3398FF",
    blue50: "#3398FF1A",
    blue500: "#0A84FF",
    blue600: "#1271FF",
    blue700: "#1D5BFE",
    blue800: "#114CE9",
    blue900: "#0036C0",
    "border/Accent": "#3FE964",
    "border/AccentLow": "#8CF2A4",
    "border/Disabled": "#CCE5FF14",
    "border/Inverse": "#131314",
    "border/Negative": "#FF3860",
    "border/Positive": "#3399FF",
    "border/Primary": "#CCE5FF24",
    "border/Selected": "#FFFFFF",
    "border/Warning": "#FFDD33",
    carrot100: "#FED4CB",
    carrot200: "#FFC1B2",
    carrot300: "#FFA18C",
    carrot400: "#FF8E75",
    carrot50: "#FC8D741A",
    carrot500: "#FC7153",
    carrot600: "#F76548",
    carrot700: "#F2533F",
    carrot800: "#E04B3A",
    carrot900: "#CC4033",
    "content/Accent": "#66EE84",
    "content/Disabled": "#5C6670",
    "content/InversePrimary": "#141415",
    "content/Negative": "#FF5778",
    "content/Positive": "#3399FF",
    "content/Primary": "#FFFFFF",
    "content/Quaternary": "#5C6670",
    "content/Secondary": "#DEE4E8",
    "content/Tertiary": "#A9B2BC",
    "content/Warning": "#FFD60A",
    cyan100: "#B7F0D8",
    cyan200: "#93EBC6",
    cyan300: "#6CE3B1",
    cyan400: "#45DB9C",
    cyan50: "#45DB9C1A",
    cyan500: "#1DD186",
    cyan600: "#1BC47D",
    cyan700: "#17AD74",
    cyan800: "#159C6A",
    cyan900: "#128A5C",
    darkgray: "#1C1D1E",
    downKr: "#3398FF",
    downKrLow: "#0758BA",
    downUs: "#FF587A",
    downUsLow: "#B22742",
    gold100: "#FFF1AD",
    gold200: "#FFEA84",
    gold300: "#FFE45C",
    gold400: "#FFDD33",
    gold50: "#FFDD331A",
    gold500: "#FFD60A",
    gold600: "#FCCA00",
    gold700: "#FAB700",
    gold800: "#E8A300",
    gold900: "#DE9400",
    gray100: "#CDE6FF24",
    gray200: "#414951",
    gray300: "#5C6670",
    gray400: "#7E8993",
    gray50: "#CDE6FF14",
    gray500: "#A8B1BB",
    gray600: "#C6CFD6",
    gray700: "#DFE5E9",
    gray800: "#ECEFF2",
    gray900: "#FFFFFF",
    green100: "#B2F6C1",
    green200: "#8CF2A3",
    green300: "#66EE84",
    green400: "#3FE965",
    green50: "#3FE9651A",
    green500: "#19E546",
    green600: "#17D641",
    green700: "#16C948",
    green800: "#12B049",
    green900: "#0E8A40",
    indigo100: "#C9C9F7",
    indigo200: "#AEADF2",
    indigo300: "#9492EE",
    indigo400: "#7977EA",
    indigo50: "#7977EA1A",
    indigo500: "#5E5CE6",
    indigo600: "#4E4CD9",
    indigo700: "#413FD1",
    indigo800: "#3836BF",
    indigo900: "#3331AD",
    lime100: "#F3FFAC",
    lime200: "#F0FF94",
    lime300: "#E9FF63",
    lime400: "#DEFA30",
    lime50: "#DEFA301A",
    lime500: "#D0F200",
    lime600: "#C6E505",
    lime700: "#BDDB02",
    lime800: "#B2CF03",
    lime900: "#A2BD02",
    orange100: "#FFDFAD",
    orange200: "#FFCF84",
    orange300: "#FFBF5C",
    orange400: "#FFAF33",
    orange50: "#FFAF331A",
    orange500: "#FF9F0A",
    orange600: "#FC8B0A",
    orange700: "#FC7F0A",
    orange800: "#F5700A",
    orange900: "#EB6709",
    pink100: "#FFD1ED",
    pink200: "#FFADE0",
    pink300: "#FF93D4",
    pink400: "#FF75C6",
    pink50: "#FF75C61A",
    pink500: "#FF58AE",
    pink600: "#F2449B",
    pink700: "#E03F90",
    pink800: "#CC3983",
    pink900: "#B23273",
    plum100: "#EECEFE",
    plum200: "#DFACF8",
    plum300: "#D491F6",
    plum400: "#CA76F4",
    plum50: "#CA76F41A",
    plum500: "#BF5AF2",
    plum600: "#AC43E0",
    plum700: "#9637C7",
    plum800: "#8530B0",
    plum900: "#722996",
    purple100: "#D4CCFF",
    purple200: "#BFB2FF",
    purple300: "#AA99FF",
    purple400: "#9480FF",
    purple50: "#9480FF1A",
    purple500: "#7F66FF",
    purple600: "#6E53F5",
    purple700: "#6046E8",
    purple800: "#5238D6",
    purple900: "#4931BF",
    red100: "#FFC2CF",
    red200: "#FF9EB1",
    red300: "#FF7A94",
    red400: "#FF587A",
    red50: "#FF587A1A",
    red500: "#FF375F",
    red600: "#F03558",
    red700: "#DE3152",
    red800: "#D12848",
    red900: "#B22742",
    same: "#A8B1BB",
    sameLow: "#414951",
    sky100: "#CBF0FF",
    sky200: "#B1E8FF",
    sky300: "#98E1FF",
    sky400: "#7ED9FF",
    sky50: "#7ED9FF1A",
    sky500: "#64D2FF",
    sky600: "#2FC3FF",
    sky700: "#1FACED",
    sky800: "#1D97DE",
    sky900: "#1B82CC",
    teal100: "#BFEEF5",
    teal200: "#9FE5EF",
    teal300: "#80DCEA",
    teal400: "#60D4E5",
    teal50: "#60D4E51A",
    teal500: "#40CBE0",
    teal600: "#2EBBD4",
    teal700: "#1FABC4",
    teal800: "#1593AB",
    teal900: "#127F94",
    upKr: "#FF587A",
    upKrLow: "#B22742",
    upUs: "#66EE84",
    upUsLow: "#0E8A40",
    white: "#FFFFFF",
    yellow100: "#FFF8AA",
    yellow200: "#FFF480",
    yellow300: "#FFF155",
    yellow400: "#FFED2B",
    yellow50: "#FFED2B1A",
    yellow500: "#FFEA00",
    yellow600: "#FCDF00",
    yellow700: "#F2D200",
    yellow800: "#E8C500",
    yellow900: "#DBB300",
  },
};

export const LIGHT_THEME = {
  colors: {
    "Shallow/Shallow below": "#C4C4C4",
    "bg/Accent": "#14C93A",
    "bg/AccentLow": "#EDFCF0",
    "bg/AlwaysDark": "#000000",
    "bg/AlwaysLight": "#FFFFFF",
    "bg/Deep": "#F5F7F8",
    "bg/Dim": "#0000004D",
    "bg/Disabled": "#3D59760D",
    "bg/DownKrLow": "#E5F2FF",
    "bg/DownUsLow": "#FFEBEE",
    "bg/Elevated": "#FFFFFF",
    "bg/InversePrimary": "#131414",
    "bg/InverseSecondary": "#333B41",
    "bg/Negative": "#FF2D55",
    "bg/NegativeLow": "#FFEBEE",
    "bg/Overlay": "#FFFFFFB3",
    "bg/Positive": "#007BFF",
    "bg/PositiveLow": "#E5F2FF",
    "bg/Primary": "#FFFFFF",
    "bg/SameLow": "#3D59760D",
    "bg/Secondary": "#3D59760D",
    "bg/Tertiary": "#3D59761A",
    "bg/UpKrLow": "#FFEBEE",
    "bg/UpUsLow": "#E9F8EC",
    "bg/Warning": "#F98500",
    "bg/WarningLow": "#FFF9E0",
    black: "#000000",
    blue100: "#AED5FF",
    blue200: "#80BDFF",
    blue300: "#55A7FF",
    blue400: "#2B91FF",
    blue50: "#E5F2FF",
    blue500: "#007BFF",
    blue600: "#0467FD",
    blue700: "#0C4EFB",
    blue800: "#0642E2",
    blue900: "#0036C0",
    "border/Accent": "#18DC43",
    "border/AccentLow": "#8BEDA1",
    "border/Disabled": "#3D59760D",
    "border/Inverse": "#FFFFFF",
    "border/Negative": "#FF2D55",
    "border/Positive": "#007BFF",
    "border/Primary": "#3D59761A",
    "border/Selected": "#131414",
    "border/Warning": "#F7C700",
    carrot100: "#FECCC1",
    carrot200: "#FDB2A1",
    carrot300: "#FD9882",
    carrot400: "#FC7F63",
    carrot50: "#FEEEEA",
    carrot500: "#FC6544",
    carrot600: "#F75534",
    carrot700: "#E54935",
    carrot800: "#D13B2A",
    carrot900: "#B83125",
    "content/Accent": "#14C93A",
    "content/Disabled": "#C2CAD1",
    "content/InversePrimary": "#FFFFFF",
    "content/Negative": "#FF2D55",
    "content/Positive": "#007BFF",
    "content/Primary": "#131414",
    "content/Quaternary": "#C2CAD1",
    "content/Secondary": "#4F5861",
    "content/Tertiary": "#7E8993",
    "content/Warning": "#F98500",
    cyan100: "#B3EBD4",
    cyan200: "#8DE1BE",
    cyan300: "#67D8A8",
    cyan400: "#41CE93",
    cyan50: "#EEFCF6",
    cyan500: "#1BC47D",
    cyan600: "#18B070",
    cyan700: "#159C68",
    cyan800: "#12875C",
    cyan900: "#0F734D",
    darkgray: "#1C1D1E",
    downKr: "#007BFF",
    downKrLow: "#80BDFF",
    downUs: "#FF2D55",
    downUsLow: "#FF96AA",
    gold100: "#FFF5CC",
    gold200: "#FFEDA3",
    gold300: "#FFE373",
    gold400: "#FFDB4D",
    gold50: "#FFF9E0",
    gold500: "#FFCC00",
    gold600: "#F5BC00",
    gold700: "#F0A400",
    gold800: "#E08E00",
    gold900: "#D97C02",
    gray100: "#3D59761A",
    gray200: "#D7DEE3",
    gray300: "#C2CAD1",
    gray400: "#A2ADB7",
    gray50: "#3D59760D",
    gray500: "#7E8993",
    gray600: "#68727C",
    gray700: "#4F5861",
    gray800: "#333B41",
    gray900: "#131414",
    green100: "#B2F3C0",
    green200: "#8BEDA1",
    green300: "#65E882",
    green400: "#3FE262",
    green50: "#EDFCF0",
    green500: "#18DC43",
    green600: "#14C93A",
    green700: "#14B842",
    green800: "#109C41",
    green900: "#0E853E",
    indigo100: "#C7C7F1",
    indigo200: "#ABAAEA",
    indigo300: "#908EE4",
    indigo400: "#7472DD",
    indigo50: "#EDEDFF",
    indigo500: "#5856D6",
    indigo600: "#4B49D1",
    indigo700: "#3F3CC9",
    indigo800: "#3533B5",
    indigo900: "#302EA3",
    lime100: "#F3FFAC",
    lime200: "#ECFF7A",
    lime300: "#E5FF49",
    lime400: "#D7FA00",
    lime50: "#F9FFD7",
    lime500: "#CAEB00",
    lime600: "#C2E105",
    lime700: "#B8D602",
    lime800: "#ADC903",
    lime900: "#9EB802",
    orange100: "#FFE1B7",
    orange200: "#FFCA80",
    orange300: "#FFB855",
    orange400: "#FFA72B",
    orange50: "#FFF7EB",
    orange500: "#FF9500",
    orange600: "#F98500",
    orange700: "#F27500",
    orange800: "#ED6300",
    orange900: "#E85500",
    pink100: "#FFCCE5",
    pink200: "#FFA6D2",
    pink300: "#FF84C1",
    pink400: "#FF60AF",
    pink50: "#FFEBF5",
    pink500: "#FF3A9D",
    pink600: "#F51B88",
    pink700: "#E00773",
    pink800: "#C60163",
    pink900: "#AC0257",
    plum100: "#EECEFE",
    plum200: "#DDADF5",
    plum300: "#CF90F0",
    plum400: "#BC6FE3",
    plum50: "#F6E4FF",
    plum500: "#AF52DE",
    plum600: "#9E3ECF",
    plum700: "#8B33B8",
    plum800: "#772B9E",
    plum900: "#652585",
    purple100: "#D3CAFF",
    purple200: "#BDB0FF",
    purple300: "#A796FF",
    purple400: "#917BFF",
    purple50: "#ECE9FF",
    purple500: "#7B61FF",
    purple600: "#6C51F0",
    purple700: "#5D43E0",
    purple800: "#4E35CC",
    purple900: "#442EB2",
    red100: "#FFB9C6",
    red200: "#FF96AA",
    red300: "#FF738E",
    red400: "#FF5071",
    red50: "#FFEBEE",
    red500: "#FF2D55",
    red600: "#ED1C44",
    red700: "#CB183A",
    red800: "#B91635",
    red900: "#A31B35",
    same: "#68727C",
    sameLow: "#C2CAD1",
    sky100: "#BFE9FC",
    sky200: "#9BDBF7",
    sky300: "#7ACEF5",
    sky400: "#54BBEA",
    sky50: "#E5F5FC",
    sky500: "#32ADE6",
    sky600: "#219BDC",
    sky700: "#1F87D1",
    sky800: "#1D76C4",
    sky900: "#1B66B5",
    teal100: "#BAE5EC",
    teal200: "#97D7E3",
    teal300: "#75CADA",
    teal400: "#53BDD0",
    teal50: "#EBFAFC",
    teal500: "#30B0C7",
    teal600: "#279DB2",
    teal700: "#198A9E",
    teal800: "#11778A",
    teal900: "#0E6373",
    upKr: "#FF2D55",
    upKrLow: "#FF96AA",
    upUs: "#14C93A",
    upUsLow: "#8BEDA1",
    white: "#FFFFFF",
    yellow100: "#FFFAC4",
    yellow200: "#FFF88E",
    yellow300: "#FFF563",
    yellow400: "#FFEB00",
    yellow50: "#FFFDE0",
    yellow500: "#FFDD00",
    yellow600: "#FCD200",
    yellow700: "#F7C700",
    yellow800: "#ECBA03",
    yellow900: "#DEAC01",
  },
};

const COLORS = {
  light: LIGHT_THEME,
  dark: DARK_THEME,
};

export default COLORS;
