import { themeState } from "@src/common/theme/atoms";
import { useRecoilValue } from "recoil";

/**
 *
 * @returns 현재 컬러모드를 반환합니다.
 */
export const useGetColorMode = () => {
  const colorMode = useRecoilValue(themeState);

  return colorMode;
};
