const size = {
  mobile: 375,
  tablet: 744,
  laptop: 1080,
} as const;

const query = {
  mobile: `screen and (max-width: ${size.mobile}px)`,
  tablet: `screen and (max-width: ${size.tablet}px)`,
  laptop: `screen and (max-width: ${size.laptop}px)`,
};

const constants = {
  mobile: "mobile",
  tablet: "tablet",
  laptop: "laptop",
} as const;

const media = {
  size,
  query,
  constants,
};

export default media;
