import { AUTHORIZED_ACCESS_PATHS, PATHS } from "@src/common/constants/routes";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { ReactNode, useEffect } from "react";

/* 
Auth Guard 역할을 합니다.

NextAuth의 세션 유지 방식 특성상 첫 session fetch(/api/session) 이후 상태 업데이트가 되어야 client side에서 auth에 접근 할 수 있는데,
불필요한 인증을 막기 위해 인증이 필요한 페이지에서 fetch가 완료 전 렌더를 차단합니다.
*/
const Auth = ({ children }: { children: ReactNode }) => {
  const { status, data: session } = useSession();
  const router = useRouter();

  useEffect(() => {
    if (session?.error) {
      signOut({ callbackUrl: PATHS.AUTH });
      return;
    }
  }, [session]);

  // 인증이 필요한 페이지이면 토큰 갱신 전 render를 차단
  if (
    status === "loading" &&
    AUTHORIZED_ACCESS_PATHS.some(path => router.pathname.includes(path))
  ) {
    return <></>;
  }

  return <>{children}</>;
};

export default Auth;
