import { IndicatorInputsProps } from "../types/indicator.types";
import { CHART_INDICATORS, INDICATORS } from "@dominoapp/core-utils";

export const CHART_LAYOUT = {
  SINGLE: "SINGLE",
  VERTICAL: "VERTICAL",
  SQUARE: "SQUARE",
} as const;

export const MINI_CHART_MAX_WIDTH = 277;

export const INDICATOR_POPUP_DEFAULT_WIDTH = 280;

export const MOVING_AVERAGE = {
  TYPES: [
    {
      title: "SMA",
      krTitle: "단순이동평균",
      isOn: true,
    },
    {
      title: "EMA",
      krTitle: "지수이동평균",
      isOn: false,
    },
  ],
  MAX_CELL: 8,
};

export const MOVING_AVERAGE_INITIAL_INPUTS: IndicatorInputsProps[] = [
  {
    id: 1,
    title: "windowSize",
    titleKr: "기간",
    value: 5,
    min: 1,
    max: 480,
  },
  {
    id: 2,
    title: "windowSize",
    titleKr: "기간",
    value: 10,
    min: 1,
    max: 480,
  },
  {
    id: 3,
    title: "windowSize",
    titleKr: "기간",
    value: 20,
    min: 1,
    max: 480,
  },
  {
    id: 4,
    title: "windowSize",
    titleKr: "기간",
    value: 60,
    min: 1,
    max: 480,
  },
  {
    id: 5,
    title: "windowSize",
    titleKr: "기간",
    value: 120,
    min: 1,
    max: 480,
  },
];

export const BOLLINGER_BAND_INITIAL_INPUTS: IndicatorInputsProps[] = [
  {
    id: 1,
    title: "windowSize",
    titleKr: "기간",
    value: 20,
    min: 1,
    max: 150,
  },
  {
    id: 2,
    title: "multiplier",
    titleKr: "표준편차",
    value: 2,
    min: 1,
    max: 3,
  },
];

export const VOLUME_PROFILE_INITIAL_INPUTS: IndicatorInputsProps[] = [
  {
    id: 1,
    title: "bins",
    titleKr: "개수",
    value: 12,
    min: 2,
    max: 30,
  },
];

export const STOCHASTIC_INITIAL_INPUTS: IndicatorInputsProps[] = [
  {
    id: 1,
    title: "windowSize",
    titleKr: "기간",
    value: 14,
    min: 2,
    max: 120,
  },
  {
    id: 2,
    title: "kWindowSize",
    titleKr: "%K",
    value: 3,
    min: 1,
    max: 120,
  },
  {
    id: 3,
    title: "dWindowSize",
    titleKr: "%D",
    value: 3,
    min: 1,
    max: 120,
  },
];

export const MACD_INITIAL_INPUTS: IndicatorInputsProps[] = [
  {
    id: 1,
    title: "slow",
    titleKr: "장기",
    value: 26,
    min: 12,
    max: 120,
  },
  {
    id: 2,
    title: "fast",
    titleKr: "단기",
    value: 12,
    min: 1,
    max: 120,
  },
  {
    id: 3,
    title: "signal",
    titleKr: "시그널",
    value: 9,
    min: 2,
    max: 40,
  },
];

export const RSI_INITIAL_INPUTS: IndicatorInputsProps[] = [
  {
    id: 1,
    title: "windowSize",
    titleKr: "기간",
    value: 14,
    min: 2,
    max: 120,
  },
];

export const INDICATOR_IMAGE_S3 = `${process.env.NEXT_PUBLIC_S3_BUCKET_STATIC_URL}/chart-assets/indicators`;

export const MULTI_CHART_CONTROLLER_DELETE = "delete";
export const MULTI_CHART_CONTROLLER_ADD = "add";
export const MULTI_CHART_CONTROLLER_VISIBLE = "visible";
export const MULTI_CHART_CONTROLLER_UPDATE = "update";
export const MULTI_CHART_CONTROLLER_RESET = "reset";

export const INDICATOR_DESCRIPTION = {
  BOLLINGER_BAND: {
    DESCRIPTION:
      "볼린저밴드는 가격의 기준이 되는 중심선(n일 이동평균선)과 표준편차를 이용하여 상한선과 하한선을 표시한 변동성 지표입니다. 주가의 변동이 대부분 표준정규분포 함수에 의해 그려지는 밴드 내에서 발생한다는 것을 전제로, 밴드의 변화 혹은 가격의 밴드 이탈 여부 등의 변동성을 바탕으로 미래가격을 예측하는데 사용합니다.",
  },
  MACD: {
    DESCRIPTION:
      "장·단기 이동평균선간 차이를 이용하여 매매신호를 포착하는 기법으로, 주가 추세의 힘, 방향성, 시간을 측정하기 위해 사용하는 추세 지표입니다. 이동평균선의 단점인 시차를 보완하기 위해 만들어진 기법으로, 장·단기 이동평균선이 가장 멀어지는 시점을 시세의 전환점으로 간주하여 매매 타이밍에 반영할 수 있습니다. 장·단기 이동평균의 차인 MACD곡선과 MACD에 지수이동평균을 적용한 시그널 곡선이 교차하는 지점이 추세 전환 시점입니다.",
  },
  MOVING_AVERAGE: {
    DESCRIPTION:
      "이동평균선은 일정 기간의 가격 평균을 표시하는 기술적 지표로, 일시적인 잡음과 변동성을 제거한 추세를 파악하는데에 도움이 됩니다. 일반적으로 사용되는 기술적 분석 방법으로, 이동평균선 개념이 기본적으로 대부분의 추세 지표에 적용됩니다.",
    SUB_OPTIONS: {
      SMA: {
        TITLE: "단순이동평균(SMA, Simple Moving Average)",
        DESCRIPTION:
          "일정 기간의 가격을 동일한 비중으로 반영하여 평균을 계산하는 방법",
      },
      EMA: {
        TITLE: "지수이동평균(EMA, Exponential Moving Average)",
        DESCRIPTION:
          "모든 가격의 비중이 동일하므로 현재의 추세가 과거 데이터로 인해 왜곡되는 단순이동평균의 단점을 보완한 방식으로, 최근 가격에 대해 가중치를 적용",
      },
    },
  },
  RSI: {
    DESCRIPTION:
      "RSI는 가격의 상승과 하락 추세에서 상승폭과 하락폭의 강도를 백분율로 계산해 가격 상승과 하락 압력 간의 상대적인 강도를 나타내는 기술적 지표입니다. 일정 기간 동안 가격의 전일 대비 상승분 또는 하락분의 평균 값을 구하여 상승분 평균이 크면 과매수, 하락분 평균이 크면 과매도 상태로 판단합니다. \n일반적으로 RSI 값이 70%를 웃돌면 매도, 30%를 밑돌면 매수 포지션을 취합니다.",
  },
  STOCHASTIC: {
    DESCRIPTION:
      "스토캐스틱은 일정 기간 동안의 최고가와 최저가 사이의 범위에서 현재 가격이 어느 위치에 형성되고 있는지를 나타내는 기술적 지표입니다. 주가가 상승 추세일 경우 당일 종가가 최근 며칠간의 거래범위 중 최고점 부근에 형성될 확률이 높고, 주가가 하락 추세일 경우는 반대로 최저점 부근에 형성될 확률이 높다는 점에 기반합니다. %K는 현재 가격이 최근 며칠간의 가격 범위에서 어디에 위치하는지를 나타내며, 0%에서 100%사이의 값입니다. 100%에 가까울수록 과매수 상태에 있다고 판단하고, 0%에 가까울수록 과매도 상태에 있다고 판단할 수 있습니다.",
  },
  VOLUME_PROFILE: {
    DESCRIPTION:
      "매물대는 차트에 그려진 기간에서 특정 가격대 구간의 거래량을 수평방향 히스토그램으로 표시한 차트 지표로, 가격대별 거래량을 비율로 확인할 수 있습니다. 차트의 기간을 변경하면 매물대의 형태도 변경됩니다. 매물대는 거래량에 따라 주가의 지지선과 저항선을 나타내는 지표로 사용됩니다.",
  },
};

export const COMMODITY_TABS = {
  에너지: "ENERGY",
  금속: "METAL",
  농축산물: "AGRICULTURE",
} as const;

export const DIVIDEND = {
  DIVIDEND: "배당",
  EX_DIVIDEND: "배당락",
  EX_DIVIDEND_DATE: "배당락일",
  DIVIDEND_PAYMENT: "배당금",
  PAYMENT_DATE: "배당지급일",
};

export const DIVIDEND_TITLE = [
  DIVIDEND.EX_DIVIDEND_DATE, // 배당락일
  DIVIDEND.PAYMENT_DATE, // 배당지급일
  DIVIDEND.DIVIDEND_PAYMENT, //배당금
];

export enum STOCK_CONSENSUS_KR {
  "적극 매수",
  "매수",
  "보유",
  "매도",
  "적극 매도",
}

export const INDICE_TITLE = {
  exchanges: "지수",
  futures: "선물",
  commodities: "상품",
  exchangeRates: "환율",
  interestRates: "금리",
  govBonds: "국채",
} as const;

export const TECHNICAL_INDICATOR_EVENT_PARAM = {
  STATE: {
    ON: "on",
    OFF: "off",
  },
  TYPE: {
    [CHART_INDICATORS.MOVING_AVERAGE]: "MA",
    [CHART_INDICATORS.BOLLINGER_BAND]: "Bolinger Band",
    [CHART_INDICATORS.VOLUME_PROFILE]: "Volume Profile",
    [INDICATORS.TRADING_VOLUME]: "Volume",
    [INDICATORS.STOCHASTIC]: "Stochastic",
    [INDICATORS.MACD]: "MACD",
    [INDICATORS.RSI]: "RSI",
  },
  MA_SUBTYPE: {
    SMA: "SMA",
    EMA: "EMA",
  },
};
