const BASE_URL = "/";
const AUTH_URL = "/auth";
const TOS_URL = "/serviceAgreement/tos";
const PRIVACY_URL = "/serviceAgreement/privacy";
const STOCK_URL = "/stock";
const ERROR_URL = "/error";
const ASSETS_URL = "/assets";
const CHART_URL = "/charts";
const BULK_INSERT_URL = "/bulk-insert/upload";
const SERVICE_AGREEMENT = "/serviceAgreement";
const NOTICE_URL =
  "https://weffwd.notion.site/50820899085c459196b3989b0e57d1a1";

export const PATHS = {
  HOME: BASE_URL,
  AUTH: AUTH_URL,
  AUTH_AGREEMENT: `${AUTH_URL}/agreement`,
  AUTH_PROFILE: `${AUTH_URL}/profile`,
  TOS: TOS_URL,
  PRIVACY: PRIVACY_URL,
  STOCK_DETAIL: STOCK_URL,
  ERROR: ERROR_URL,
  ASSETS: ASSETS_URL,
  CHARTS: CHART_URL,
  BULK_INSERT: BULK_INSERT_URL,
  SERVICE_AGREEMENT: SERVICE_AGREEMENT,
  NOTICE: NOTICE_URL,
};

export const AUTHORIZED_ACCESS_PATHS = [
  PATHS.CHARTS,
  PATHS.ASSETS,
  PATHS.BULK_INSERT,
];

export const USER_AUTHORIZED_PATHS = [
  PATHS.AUTH,
  PATHS.AUTH_PROFILE,
  PATHS.AUTH_AGREEMENT,
];

export const AGREEMENT_AUTH_URL = "/api/auth/session/agreement";
export const PROFILE_AUTH_URL = "/api/auth/session/profile";

export const PATH_CATEGORY = {
  TERMS: "TERMS",
  AUTH: "AUTH",
  NEED_AUTHORIZE: "NEED_AUTHORIZE",
  LANDING: "LANDING",
} as const;

export const getPathCategory = (pathname: string) => {
  if (pathname.startsWith(PATHS.PRIVACY) || pathname.startsWith(PATHS.TOS)) {
    return PATH_CATEGORY.TERMS;
  }

  if (USER_AUTHORIZED_PATHS.includes(pathname)) {
    return PATH_CATEGORY.AUTH;
  }

  if (AUTHORIZED_ACCESS_PATHS.includes(pathname)) {
    return PATH_CATEGORY.NEED_AUTHORIZE;
  }

  return PATH_CATEGORY.LANDING;
};
