import React from "react";
import { Global, useTheme } from "@emotion/react";
import { useRouter } from "next/router";
import { MarkdownGlobalStyles } from "@src/common/styles/markdownGlobalStyles";
import { GlobalStyles } from "@src/common/styles/globalStyles";

const GlobalThemeWrapper = () => {
  const router = useRouter();
  const theme = useTheme();
  const markDownPages = ["serviceAgreement"];

  const isMarkDownPage = markDownPages.some(page =>
    router.pathname.includes(page),
  );

  const getGlobalStyle = () => {
    if (isMarkDownPage) {
      return MarkdownGlobalStyles;
    } else {
      const isHome = router.pathname === "/";
      return GlobalStyles(theme, {
        isHome,
      });
    }
  };

  const globalStyle = getGlobalStyle();

  return <Global styles={globalStyle} />;
};

export default GlobalThemeWrapper;
