import React from "react";
import { ThemeProvider } from "@emotion/react";
import COLORS from "./theme";
import { zindex } from "./zIndex";
import { useGetColorMode } from "../components/Social/Profile/hooks/useGetColorMode";
import SHADOW from "./shadow";

interface ThemeProps {
  children: React.ReactNode;
}

const ThemeWrapper = ({ children }: ThemeProps) => {
  const theme = useGetColorMode();
  return (
    <ThemeProvider
      theme={{
        ...COLORS[theme],
        ...SHADOW[theme],
        zindex,
      }}
    >
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
