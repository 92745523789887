import { atom, AtomEffect } from "recoil";
import { THEME } from "./theme";
import { Theme } from "./types";

export const THEME_STATE = "themeState";

const store = typeof window !== "undefined" ? window.localStorage : null;

const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    if (store) {
      const savedValue = store.getItem(key);
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      onSet((newValue, _, isReset) => {
        isReset
          ? store.removeItem(key)
          : store.setItem(key, JSON.stringify(newValue));
      });
    }
  };

export const themeState = atom<Theme>({
  key: THEME_STATE,
  default: THEME.DARK_MODE,
  effects: [localStorageEffect(THEME_STATE)],
});
