import { useRouter } from "next/router";
import { useEffect } from "react";
import * as gtag from "../lib/gtag";

/**
 * ga 라우팅 변경에 따른 조회수 측정 로거
 */
export const useRouteChangeLogger = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);
};
